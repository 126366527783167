import { useState, useEffect, useRef } from 'react';
import { MaplibreMap } from '@/entities/map/types';
import { LatLngCoordinates } from '@/shared/types';
import { useContextMenu, useDrawLine } from './hooks';
import marker from './marker';

const useContextMenuTrigger = (map: MaplibreMap | null) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState<LatLngCoordinates | null>(null);
  const { distanceUnit, distanceValue, createRuler } = useDrawLine(map);
  const items = useContextMenu({ coords, createRuler });

  const recalculatePosition = () => triggerRef.current?.click();

  useEffect(() => {
    if (!map?.getStyle()) return;

    const openContextMenu = (e: MouseEvent) => {
      const { lat, lng } = map.unproject([e.offsetX, e.offsetY]);

      recalculatePosition();
      setCoords({ lat, lng });
      marker.setLngLat({ lat, lng }).addTo(map);
    };

    const closeContextMenu = () => {
      setCoords(null);
      marker.remove();
    };

    recalculatePosition();

    // map.on('contextmenu') doesn't work on mobile devices
    map.getCanvas().addEventListener('contextmenu', openContextMenu);
    map.on('movestart', closeContextMenu);
    map.on('zoomstart', closeContextMenu);
    document.addEventListener('click', closeContextMenu);
    return () => {
      map.getCanvas().removeEventListener('contextmenu', openContextMenu);
      map.off('movestart', closeContextMenu);
      map.off('zoomstart', closeContextMenu);
      document.removeEventListener('click', closeContextMenu);
    };
  }, [map]);

  return {
    triggerRef,
    items,
    distanceUnit,
    distanceValue,
  };
};

export default useContextMenuTrigger;
