import RecordActions from '@/entities/source/@fox/components/RecordsTab/components/RecordActions';
import {
  useFoxRecordsQueryParams,
  useGetFiles,
  useNavigateToRecord,
} from '@/entities/source/@fox/components/RecordsTab/hooks';
import { File } from '@/entities/source/@fox/types';
import { FILES_QUERY_KEY } from '@/shared/constants';
import { useParams, useReFetchInfiniteListOnTop, useScrollUpToReFetch, useScrollTopPosition } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { DetailsHeader, MetadataDetails } from '../components';
import styles from '../styles.module.css';

const useMobileRecordsList = () => {
  const { sourceId } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();
  const navigateToRecord = useNavigateToRecord();

  const { filesList, getFileRef, isFilesLoading, isFilesFetching } = useGetFiles({ queryParams });
  const { handleScrollUpToReFetch, isReFetchingByScrollUp } = useScrollUpToReFetch([
    FILES_QUERY_KEY,
    sourceId,
    queryParams,
  ]);
  const isLoading = (isFilesLoading || isFilesFetching) && !isReFetchingByScrollUp;

  const rowClassName = (item: File) => {
    if (!item.metadata.hasVoice || !item.metadata.isDecoded) return styles.halfTransparentRow;
  };

  const renderDetailsTitle = (file: File) => <DetailsHeader file={file} />;
  const renderDetailsComponent = () => <MetadataDetails />;

  const renderActions = (file: File, _onEdit: VoidFunction, onDelete: VoidFunction) => (
    <RecordActions
      id={file.id}
      fileName={file.fileName}
      onDelete={onDelete}
    />
  );

  const handleItemClick = (item: File) => {
    navigateToRecord({
      routeParams: {
        sourceId,
        tabEntityId: item.id,
      },
      options: { withAllQueryParams: true },
    });
  };

  const { handleScroll, scrollTopPosition } = useScrollTopPosition();
  useReFetchInfiniteListOnTop({ queryKey: [FILES_QUERY_KEY, sourceId, queryParams], scrollTopPosition });

  return {
    filesList,
    getFileRef,
    isLoading,
    rowClassName,
    renderDetailsTitle,
    renderDetailsComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    handleScrollUpToReFetch,
    scrollTopPosition,
  };
};

export default useMobileRecordsList;
