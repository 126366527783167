import { TableColumnsType } from 'antd';
import { FileUnknownOutlined } from '@ant-design/icons';
import { useUnmount } from '@reactuses/core';
import classNames from 'classnames';
import PlayButton from '@/entities/source/@fox/components/RecordsTab/components/PlayButton';
import RecordActions from '@/entities/source/@fox/components/RecordsTab/components/RecordActions';
import RecordName from '@/entities/source/@fox/components/RecordsTab/components/RecordName';
import RecordType from '@/entities/source/@fox/components/RecordsTab/components/RecordType';
import {
  useFoxRecordsQueryParams,
  useGetFiles,
  useNavigateToRecord,
} from '@/entities/source/@fox/components/RecordsTab/hooks';
import { useRow } from '@/entities/source/@fox/hooks';
import foxStyles from '@/entities/source/@fox/styles.module.css';
import { File } from '@/entities/source/@fox/types';
import { setSelectedRecordId, setIsRecordPlaying } from '@/entities/source/slices';
import { FILES_QUERY_KEY, FoxRecordsQueryParam } from '@/shared/constants';
import {
  useAppDispatch,
  useParams,
  useReFetchInfiniteListOnTop,
  useScrollUpToReFetch,
  useScrollTopPosition,
  useSearchParam,
} from '@/shared/hooks';
import { timeService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import styles from '../styles.module.css';
import useRedirectToRecordList from './useRedirectToRecordList';

enum ColumnTitle {
  Record = 'ЗАПИС',
  Frequency = 'ЧАСТОТА',
  Type = 'ТИП',
  Сreated = 'ПЕРЕХОПЛЕНО',
}

const NOT_DECODED_SIGNAL = 'Не декодований сигнал';

const useRecordsTable = () => {
  const dispatch = useAppDispatch();
  const queryParams = useFoxRecordsQueryParams();
  const { filesList, getFileRef, isFilesFetching } = useGetFiles({ queryParams });
  const { sourceId = '', tabEntityId } = useParams<SourceRouteParams>();
  const { handleScrollUpToReFetch, isReFetchingByScrollUp } = useScrollUpToReFetch([
    FILES_QUERY_KEY,
    sourceId,
    queryParams,
  ]);
  const { handleScroll, scrollTopPosition } = useScrollTopPosition();
  useReFetchInfiniteListOnTop({
    queryKey: [FILES_QUERY_KEY, sourceId, queryParams],
    scrollTopPosition,
    isRefetchEnabled: !isReFetchingByScrollUp,
  });
  const navigateToRecord = useNavigateToRecord();
  const handleRow = useRow<File>(navigateToRecord);
  const [search] = useSearchParam(FoxRecordsQueryParam.Search);

  useRedirectToRecordList({ list: filesList, listItemPropertyName: 'id', compareValue: tabEntityId });

  const rowClassName = ({ id, metadata }: File) => {
    return classNames(foxStyles.row, {
      [foxStyles.activeRow]: id === tabEntityId,
      [styles.halfTransparentRow]: !metadata.hasVoice || !metadata.isDecoded,
    });
  };

  useUnmount(() => {
    dispatch(setSelectedRecordId(null));
    dispatch(setIsRecordPlaying(false));
  });

  const columns: TableColumnsType<File> = [
    {
      dataIndex: 'fileName',
      title: ColumnTitle.Record,
      className: styles.fileName,
      width: '50%',
      render: (_, file: File, index) => (
        <RecordName
          fileName={file.metadata.isDecoded ? file.fileName : NOT_DECODED_SIGNAL}
          listItemRef={getFileRef(index)}
          additionalElement={
            file.metadata.isDecoded ? <PlayButton id={file.id} /> : <FileUnknownOutlined className={styles.icon} />
          }
          search={search}
        />
      ),
    },
    {
      dataIndex: 'frequency',
      title: ColumnTitle.Frequency,
      width: '70px',
      render: (_, file: File) => file.metadata?.frequency,
    },
    {
      dataIndex: 'encryptionType',
      title: ColumnTitle.Type,
      width: '70px',
      render: (_, file: File) => (
        <RecordType
          signalType={file.metadata.signalType}
          encryptionType={file.metadata.encryptionType}
        />
      ),
    },
    {
      dataIndex: 'createdAt',
      title: ColumnTitle.Сreated,
      width: '70px',
      render: (_, file: File) => timeService.getFormattedFullTimeOrDate(file.metadata.interceptedAt),
    },
    {
      dataIndex: 'id',
      width: '32px',
      className: styles.column,
      render: (_, file: File) =>
        file.metadata.isDecoded && (
          <RecordActions
            id={file.id}
            fileName={file.fileName}
          />
        ),
    },
  ];

  return {
    filesList,
    columns,
    isFilesFetching: isFilesFetching && !isReFetchingByScrollUp,
    handleScrollUpToReFetch,
    handleScroll,
    handleRow,
    rowClassName,
  };
};

export default useRecordsTable;
